import React from 'react';
import logo from './logo.svg';
import './App.css';



/*
%%<!--
%%//        <p>
%%//          Toilet paper shortages are a good example of this - but there's enough for everyone 
%%//          -- hence the great toilet paper exchange!
%%        </p>
%%-->
*/






function App() {
  return (
    <div className="App-background" >
    <div className="App">
      <header className="App-header">

        <h1 className="App-title-text">
          <center>
          The Great <span className="covid19">(Covid-19)</span> Toilet Paper Exhange
          </center>
        </h1>


        <p className="App-tagline-text">
        The Covid-19 pandemic has challenged us individually and as a society, 
        creating shortages of essential supplies for people looking to meet their basic needs. 
        </p>

        <p className="App-goal-text">
          <b>Our Goal: </b> 
          To connect unused supplies of essential personal hygene goods with distributors    
          and consumers in areas where usual retail supplies are runnning low.
        </p>



        <p className="App-text">
          <b className='covid19'>How to Help: </b> 
          <ul className="App-list">
            <li>
              <b className="App-list-heading"> Distributors </b> - Are you a retail or local aid agency?  &nbsp;
              <br/>
              <div className="App-list-contact-item">
                <a href="mailto:covidtoiletpaperexchange@outlook.com?subject=Distributor Info">Contact us</a> to recieve supplies from our generous commerical suppliers.
              </div>
            </li>
            <li>
              <b className="App-list-heading"> Suppliers </b> - Are you a corporation with unused toilet paper or cleaning products sitting idle in an empty building?  &nbsp;
              <br/>
              <div className="App-list-contact-item">
                <a href="mailto:covidtoiletpaperexchange@outlook.com?subject=Supplier Info">Contact us</a> to be connected with organizations that can distribute them to people in need in your area.
              </div>
            </li>
            <li>
              <b className="App-list-heading"> Planners </b> - Are you knowledgeable about local/national aid organizations, food banks, or other relief service providers in your area?  &nbsp; 
              <br/>
              <div className="App-list-contact-item">
                <a href="mailto:covidtoiletpaperexchange@outlook.com?subject=Planner Info">Contact us</a> to connect with the planning team.
              </div>
            </li>
            <li>
              <b className="App-list-heading"> Developers </b> - Are you experienced with quickly developing web applications with Data on Cloud architecture?  &nbsp;
              <br/>
              <div className="App-list-contact-item">
                <a href="mailto:covidtoiletpaperexchange@outlook.com?subject=Developer Info">Contact us</a> to connect with the development team.
              </div>
            </li>
          </ul>
        </p>



{/*

        <p className="App-text">
          <b className='covid19'>How to Help: </b> 
          <ul className="App-list">
            <li>
              <b className="App-list-heading"> Distributors </b> - Are you a retail or local aid agency?  &nbsp;
            </li>
            <li>
              <b className="App-list-heading"> Suppliers </b> - Are you a corporation with unused toilet paper or cleaning products sitting idle in an empty building?  &nbsp;
            </li>
            <li>
              <b className="App-list-heading"> Planners </b> - Are you knowledgeable about local/national aid organizations, food banks, or other relief service providers in your area?  &nbsp; 
            </li>
            <li>
              <b className="App-list-heading"> Developers </b> - Are you experienced with quickly developing web applications with databases on Cloud architecture?  &nbsp;
            </li>
          </ul>
        </p>

        <div>
              <a href="mailto:covidtoiletpaperexchange@outlook.com">Contact us</a> at <span className="contact-email">covidtoiletpaperexchange@outlook.com</span> to let us know how you can help!  Thank you!
        </div>

*/}


        <div>
          Any other ideas to contribute?    

          <div className="contact-item">
            &nbsp; &nbsp; <a href="mailto:covidtoiletpaperexchange@outlook.com">Contact us</a> at <span className="contact-email">covidtoiletpaperexchange@outlook.com</span> to let us know how you can help!  Thank you!
          </div>
        </div>



{/*
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
*/}

      </header>
    </div>
    </div>
  );
}

export default App;
